import React from "react"
import Logo from "./logo"
import { Link } from "gatsby"
import Subscribe from "./subscribe"
import { FaEnvelope, FaPhone } from "react-icons/fa"
import Follow from "./follow"

// Styles
import styles from "../styles/components/footer.module.scss"

// Images
import nugoLogo from "../images/nugo-logo.svg"

const Footer = () => {
  const date = new Date()
  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <div className={styles.one}>
          <Logo />
          <div className={styles.title}>About Us</div>

          <p className={styles.text}>
            We are on a mission to provide the highest efficiency and lowest
            cost per watt of any solar provider within the country
          </p>
          <div className={styles.title}>Contact Us</div>
          <ul className={styles.list}>
            <li>
              <a href="tel:+94775008888" target="_top">
                <span className={styles.icon}>
                  <FaPhone />
                </span>
                +94 77 500 8888 / +94 70 500 8888
              </a>
            </li>
            <li>
              <a href="mailto:info@sunterra.lk" target="_top">
                <span className={styles.icon}>
                  <FaEnvelope />
                </span>
                info@sunterra.lk
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.two}>
          <h3 className={styles.title}>Information</h3>
          <ul className={styles.list}>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/about">Products</Link>
            </li>
            <li>
              <Link to="/articles">Articles &amp; Publications</Link>
            </li>
            <li>
              <Link to="/loans">Loans &amp; Schemes</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/quote">Quotation</Link>
            </li>
            <li>
              <Link to="/faq">FAQ's</Link>
            </li>
          </ul>
        </div>
        <div className={styles.three}>
          <h3 className={styles.title}>Subscribe</h3>
          <Subscribe />
        </div>
      </div>

      <div className={styles.copyright}>
        <p className="m-0">
          Copyright © {date.getFullYear()} Sunterra (Pvt) Ltd. All Rights
          Reserved.
        </p>
        <div className={styles.nugo}>
          <a href="https://www.nugo.xyz" rel="nofollow" target="blank">
            <img src={nugoLogo} alt="nugo" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
