/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

// Styles
import styles from "../styles/components/layout.module.scss"

const Layout = ({ children, color }) => {
  return (
    <div className={styles.wrapper} style={{ backgroundColor: color }}>
      <div className={styles.layout}>
        <main>{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
