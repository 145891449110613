import React from "react"
import { navigate } from "gatsby-link"
import { Button } from "react-bootstrap"

// Styles
import styles from "../styles/components/subscribe.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Subscribe extends React.Component {
  state = {
    email: "",
    emailValid: false,
  }

  handleChange = e => {
    if (e.target.name === "email") {
      this.validateEmail(e.target.value)
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "subscribe",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.setState({
      emailValid: re.test(String(email).toLowerCase()),
    })
  }

  render() {
    return (
      <form
        className={styles.form}
        name="subscribe"
        method="post"
        action="/subscribed/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="subscribe" />
        <label style={{ display: "none" }}>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={this.handleChange} />
        </label>
        <div className={styles.subscribe}>
          <label htmlFor="email" className={styles.label}>
            <input
              className={styles.input}
              type="email"
              name="email"
              required
              placeholder="Your Email Address"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </label>
          <Button variant="primary">Subscribe</Button>
        </div>
      </form>
    )
  }
}

export default Subscribe
